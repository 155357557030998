import React from "react";

import SEO from "../components/seo";

import GlobalStyle from '../components/layoutCss';

const NotFoundPage = (props) => (
  <>
    <GlobalStyle />
    <SEO
      title="404: Not found"
      path={props.path}
    />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </>
);

export default NotFoundPage;
